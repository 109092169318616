:root {
	--col-dark: #453944;
	--col-light: #e0dad3;
	--col-accent: #a3d9ff;
	--col-success: #c8eab3;
	--col-warn: #fbc993;
}

#root {
	min-height: 100vh;
	background-color: var(--col-dark);
	color: var(--col-light);
	--accent: var(--col-accent);
}

.App {
	margin: 0 auto;
	max-width: 115ch;
}

header {
	padding: 4rem 0 0;
}

header h1 a {
	text-decoration: none;
	color: inherit;
}

nav {
	position: sticky;
	top: 0;
	background-color: var(--col-dark);
}

nav p {
	cursor: pointer;
	font-weight: 700;
	font-size: 1.25rem;
}

h1,
h2,
h3 {
	color: var(--accent);
}

h1 {
	font-size: 6rem;
}

h2 {
	font-size: 3rem;
	margin: 4rem 0 2rem;
	scroll-margin-top: 7rem;
}

h3 {
	font-size: 1.5rem;
	margin: 2rem 0 1rem;
}

h4 {
	font-size: 1.25rem;
	margin: 1rem 0 0.5rem;
}

p {
	margin: 1rem 0;
}

a {
	color: var(--col-light);
}

ul,
ol {
	padding-left: 4rem;
}

table {
	border-spacing: 1rem;
}

:is(thead, tfoot) th {
	text-align: left;
	padding: 1rem 0;
}

thead th {
	border-bottom: 2px solid var(--col-light);
}

tfoot th {
	border-top: 2px solid var(--col-light);
}

.button {
	margin: 2rem 0;
	padding: 1rem 2rem;
	color: var(--col-dark);
	background-color: var(--accent);
	text-decoration: none;
	font-weight: 700;
	cursor: pointer;
	transition: box-shadow 0.5s, outline 0.5s;
}

.button:hover,
.button:focus {
	box-shadow: 5px 5px 10px var(--col-success);
}

.button:focus,
.button:active {
	outline: var(--col-success) solid 5px;
}

.flex {
	display: flex;
}

.flex-equal > * {
	flex-basis: 100%;
}

.gap-5r {
	gap: 5rem;
}

.gap-2r {
	gap: 2rem;
}

.flex-center {
	justify-content: center;
	align-content: center;
}

.flex-column {
	flex-direction: column;
}

.m0 {
	margin: 0;
}

.mt0 {
	margin-top: 0;
}

.m2 {
	margin: 2rem;
}

.mv2 {
	margin: 2rem 0;
}

.mb1 {
	margin-bottom: 1rem;
}

.m2 + .m2 {
	margin-top: 0;
}

.p0 {
	padding: 0;
}

.p2 {
	padding: 2rem;
}

.inline {
	display: inline;
}

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, 20ch);
}

.list-none {
	list-style: none;
}

.justify-evenly {
	justify-content: space-evenly;
}

.has-data #first-run {
	display: none;
}

#data {
	display: none;
}

.has-data #data {
	display: initial;
}

.first-child-9ch > :first-child {
	width: 9ch;
	text-align: right;
}

.pointer {
	cursor: pointer;
}